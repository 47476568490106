<template>
  <div>
    <Breadcrumbs :items="breadcrumbs" :page_title="pageTilte" :item_no="item_No" />
    <v-card class="mx-3 my-5" style="border-radius:16px;">
      <v-container>
        <v-row justify="space-between" class="mt-1">
          <v-col cols="12" sm="6" lg="4" md="4" xl="4">
            <v-text-field
              outlined
              dense
              hide-details
              style="border-radius: 8px;border-color: #C0C0C0;"
              clearable
              prepend-inner-icon="mdi-magnify"
              :placeholder="$t('search')"
              v-model="search"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
              v-model="videoselected"
              @toggle-select-all="getSelectAll"
              class="mt-1"
              :loading="loading"
              item-key="lessonName"
              show-select
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="items"
              hide-default-footer
              :search="search"
              @page-count="pageCount = $event"
              :page.sync="page"
              :items-per-page="itemsPerPage"
            >
              <template v-slot:item.lessonName="{ item }">
                <p color="#4D4F5C" class="mt-3">{{ item.lessonName }}</p>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row justify="space-between" class="mx-3 my-4">
          <v-row>
            <v-col cols="auto" class="mr-auto">
              <v-pagination circle v-model="page" class="pagination" :length="pageCount"></v-pagination>
            </v-col>

            <v-col cols="12" lg="2" md="3" sm="4" class="d-flex justify-end">
              <p class="mt-3 pr-4">{{ $t("Show")}}</p>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    width="70"
                    v-bind="attrs"
                    v-on="on"
                    style="border: 1px solid #C0C0C0; opacity: 1"
                    class="text-capitalize mt-1"
                    outlined
                  >
                    {{ itemsPerPage }}
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, index) in paginationCount"
                    :key="index"
                    @click="itemsPerPage = item.title"
                  >
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-row>
        <v-row justify="end" class="mt-7 mr-2 ml-2 mb-2">
          <v-col style="text-align: right;">
            <v-btn
              text
              outlined
              class="text-capitalize mr-3"
              style="font-size: 16px;
                     border-radius: 8px;
                     background-color: #ffffff !important;"
              width="100"
              @click="removePageTitle(),
              $store.commit('changeLibraryAction', false),
              $store.commit('closeAssignmentEditDialog'),
              $router.push('/home/assignment-course'),
              $store.commit(
                editassignment
                  ? 'openAssignmentEditDialog'
                  : 'openAssignmentDialog'
              )"
            >{{ $t("cancel") }}</v-btn>
            <v-btn
              class="text-capitalize white--text btn_hover_effect"
              width="100"
              style="
            font-size: 16px;
            border-radius: 8px;
            background-color: #A6CC39 !important;
            color: #ffffff !important;
          "
              @click="saveACVideo()"
            >{{ $t("save") }}</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <!-- dialog -->
    <v-dialog v-model="dialog" width="800" scrollable>
      <v-card>
        <v-card-title
          class="white--text"
          style="background-color: #a6cc39; font-size: 40px; height: 84px"
        >Video Lists</v-card-title>
        <v-card-text style="height: 500px">
          <v-data-table
            class="mt-5"
            v-model="customSelectedVideo"
            show-select
            key="id"
            :headers="editDataHeaders"
            :items="editData.partfileList"
            hide-default-footer
          >
            <template v-slot:item.part="{ index }">{{ index + 1 }}</template>
            <!-- Part -->
            <template v-slot:item.time="{ item }">{{ item.time }} m</template>
          </v-data-table>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="mt-3 mb-4">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            width="140"
            style="font-size: 20px"
            @click="dialog = false"
          >{{ $t("cancel") }}</v-btn>
          <v-btn
            color="#6CC8A0"
            depressed
            width="140"
            style="font-size: 20px"
            class="white--text btn_hover_effect"
            @click="saveVlist()"
          >{{ $t("save") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      item_No: 0,
      pageTilte: "",
      dialog: false,
      search: "",
      items: [],
      videoselected: [],
      addDialog: false,
      loading: true,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      paginationCount: [{ title: 10 }, { title: 20 }, { title: 30 }],
      showSuccessDialog: false,
      showDeleteDialog: false,
      deleteData: {},
      editDialog: false,
      editData: {},
      breadcrumbs: [
        {
          sidebar_tilte: "home"
        },
        {
          text: "library",
          href: "/home/library"
        },
        {
          text: "video"
        }
      ]
    };
  },

  computed: {
    ...mapGetters([
      // "selectedvideoList",
      "editVideoList",
      "editLibraryData",
      "editassignment" //showing error when fix r-10 bug
    ]),
    customSelectedVideo: {
      get() {
        return this.$store.getters.customSelectedVideo;
      },
      set(e) {
        return this.$store.commit("addCustomSelectedVideo", e);
      }
    },
    headers() {
      return [
        {
          text: this.$t("name"),
          align: "start",
          value: "lessonName"
        },
        {
          text: this.$t("description"),
          align: "start",
          value: "wordDescription"
        }
      ];
    },
    editDataHeaders() {
      return [
        {
          text: "#",
          value: "part"
        },
        {
          text: "Name",
          value: "songName"
        },
        {
          text: "TIME (MINUTES)",
          value: "time"
        }
      ];
    }
  },

  mounted() {
    this.pageTilte = localStorage.getItem("pageTitle_forCourseVideoList");
    this.breadcrumbs = [
      {
        sidebar_tilte: "home"
      },
      {
        text: "library",
        href: "/home/library"
      },
      {
        text: this.pageTilte
      },
      {
        text: "video"
      }
    ];
    this.getArticle();
  },
  methods: {
    removePageTitle() {
      localStorage.removeItem('pageTitle_forCourseVideoList')
    },
    saveVlist() {
      this.dialog = false;
      for (let i of this.customSelectedVideo) {
        const found = this.videoselected.find(c => c.id == i.gL_ID);
        if (found == undefined) {
          const newItem = this.items.find(v => v.id == i.gL_ID);
          this.videoselected.push(newItem);
        }
      }
      this.$store.commit("saveSelectedvideoList => ", this.videoselected);
    },
    checkItem() {
      for (let v of this.videoselected) {
        const found = this.items.find(c => c.id == v.id);
        if (found != undefined) {
          v.isCheck = true;
          this.customSelectedVideo = v.partfileList;
        }
      }
    },
    saveVideo() {
      let firstArray = [];
      for (let v of this.videoselected) {
        if (this.customSelectedVideo.length > 0) {
          const found = this.customSelectedVideo.find(c => c.gL_ID == v.id);
          if (found != undefined) {
            firstArray.push({
              id: 0,
              assignmentCourseID: 0,
              no: parseInt(firstArray.length + 1),
              videoName: found.songName,
              videoFile: found.videoFile,
              size: found.size,
              time: found.time,
              isUserGroup: found.isUserGroup,
              title: v.lessonName,
              oldID: 0,
              oldVideoID: found.id,
              description: v.wordDescription,
              startDateMenu: false,
              startContentDate: v.startContentDate,
              endContentDate: v.endContentDate,
              endDateMenu: false,
              pictureBackground: v.picturebackground,
              allowDownload: v.allowDownload,
              imgBase64: v.thumbnail,
              duration: this.formatDuration(found.time),
              part: parseInt(firstArray.length + 1), //"Part " +
            });
          } else {
            for (const element of v.partfileList) {
              firstArray.push({
                id: 0,
                no: parseInt(firstArray.length + 1),
                assignmentCourseID: 0,
                videoName: element.songName,
                videoFile: element.videoFile,
                size: element.size,
                time: element.time,
                isUserGroup: element.isUserGroup,
                title: v.lessonName,
                oldID: 0,
                oldVideoID: element.id,
                description: v.wordDescription,
                startDateMenu: false,
                startContentDate: v.startContentDate,
                endContentDate: v.endContentDate,
                endDateMenu: false,
                pictureBackground: v.picturebackground,
                allowDownload: v.allowDownload,
                imgBase64: v.thumbnail,
                duration: this.formatDuration(element.time),
                part: parseInt(firstArray.length + 1), //"Part " +
              });
            }
          }
        } else {
          for (const element of v.partfileList) {
            firstArray.push({
              id: 0,
              no: parseInt(firstArray.length + 1),
              assignmentCourseID: 0,
              videoName: element.songName,
              videoFile: element.videoFile,
              size: element.size,
              time: element.time,
              isUserGroup: element.isUserGroup,
              title: v.lessonName,
              oldID: 0,
              oldVideoID: element.id,
              description: v.wordDescription,
              startDateMenu: false,
              startContentDate: v.startContentDate,
              endContentDate: v.endContentDate,
              endDateMenu: false,
              pictureBackground: v.picturebackground,
              allowDownload: v.allowDownload,
              imgBase64: v.thumbnail,
              duration: this.formatDuration(element.time),
              //  permissionList:
              //  v.partfileList[i].permissionData.length > 0
              //     ? this.perList(i)
              //     : [],
              // permissionData:
              //   v.partfileList[i].permissionData.length > 0
              //     ? v.partfileList[i].permissionData
              //     : [],
              part: parseInt(firstArray.length + 1) //"Part " +
            });
          }
        }
      }
      this.$store.commit("saveeditVideoList", firstArray);
      this.customSelectedVideo = [];
      this.$router.push("/home/assignment-course");
      this.$store.commit(
        this.editassignment
          ? "openAssignmentEditDialog"
          : "openAssignmentDialog"
      );
    },
    getSelectAll() {
      if (this.videoselected.length == 0) {
        this.videoselected = this.items;
      } else {
        this.videoselected = [];
      }
    },
    saveACVideo() {
      this.$router.push("/home/assignment-course");
      this.$store.commit("saveeditVideoList", this.videoselected);
      localStorage.removeItem("pageTitle_forCourseVideoList");
    },
    async getArticle() {
      let self = this;
      let checkPermission = true;
      if (checkPermission) {
        const res = await self.$axios.post(
          `${self.web_url}GuidedLesson/GetGuidedContentTopicIDCustomerWeb`,
          {
            id: parseInt(localStorage.getItem("libraryId")),
            companyId: localStorage.getItem("companyID")
            // page: 0,
            // per_page: 0
          }
        );
        self.items = res.data.data.map((v, i) => ({
          ...v,
          no: i + 1,
          isCheck: false
        }));
        self.item_No = self.items.length;
      } else {
        self.permissionDialog = true;
        self.permissionMessage = "You don't have permission to see Video.";
      }
      self.loading = false;
    },
    getSelected() {
      this.videoselected = this.editVideoList;
    }
  }
};
</script>

<style scoped>
::v-deep .v-dialog {
  border-radius: 35px !important;
}
::v-deep tr:nth-child(even) {
  background: #e0e0e0;
}
::v-deep .v-label {
  font-size: 20px !important;
}
::v-deep .v-text-field__slot {
  font-size: 20px !important;
}
::v-deep .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  font-size: 14px !important;
  color: #424242 !important;
  background-color: #e0e0e0 !important;
  opacity: 1;
}
::v-deep .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td {
  font-size: 14px !important;
  color: #424242 !important;
  opacity: 1;
}
::v-deep .primary {
  color: #000000 !important;
  background-color: #e8e9ec !important;
  opacity: 1;
}
/* >>> .v-input--selection-controls__input .v-icon {
  color: #C0C0C0 !important;
  border-radius: 8px;
  opacity: 1;
} */
::v-deep .v-simple-checkbox .mdi-checkbox-blank-outline {
  color: #c0c0c0 !important;
}
::v-deep .v-simple-checkbox .mdi-checkbox-marked {
  color: #a6cc39 !important;
}
::v-deep .v-simple-checkbox .mdi-minus-box {
  color: #a6cc39 !important;
}
@media (min-width: 1264px) {
  .container {
    max-width: unset;
  }
}

@media (min-width: 960px) {
  .container {
    max-width: unset;
  }
}
::v-deep .pagination .primary {
  background-color: #a6cc39 !important;
  color: #ffffff !important;
  /* opacity: 1; */
}
::v-deep .v-data-table thead th:first-child {
  border-radius: 8px 0 0 8px;
} /**.child_table */
::v-deep .v-data-table thead th:last-child {
  border-radius: 0 8px 8px 0;
}
::v-deep .primary--text {
  color: #a6cc39 !important;
}
.btn_hover_effect {
  width: 50%;
  color: #fff;
  font-size: 11px;
  z-index: 0;
  position: relative;
  display: inline-block;
}
.reset-btn {
  width: 13%;
  color: #fff;
  font-size: 11px;
  z-index: 0;
  position: relative;
  display: inline-block;
}
.btn_hover_effect:before,
.reset-btn:before {
  background-color: #4fb14e;
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0.5 !important;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.btn_hover_effect:hover:before,
.reset-btn:hover:before {
  background-color: #4fb14e;
  top: 0;
}
.btn_hover_effect:hover,
.reset-btn:hover {
  transition: 0.25s;
}
.btn_hover_effect:after,
.reset-btn:after {
  position: absolute;
  right: 2.34375rem;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(50%);
  -ms-transform: translateY(-50%) translateX(50%);
  transform: translateY(-50%) translateX(50%);
}
</style>
